import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/facilities.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

export default function EndeavorPage(props) {
  return (
    <Layout>
      <Seo title="Endeavor in the West" />
      <div class="contentBox">
        <div className="mobile-back-bttn">
          <AniLink cover to="/facilities">
            Back to Facilities
          </AniLink>
        </div>
        <h1 className="facility-title">Endeavor in the West</h1>
        <div className="facility-container">
          <div className="facility-front-picture-container">
            <StaticImage
              src="../images/facilities/endeavor/endeavor-front1.jpg"
              placeholder="blurred"
              alt="frontyard"
            />
          </div>
          <div className="facility-details-container">
            <div className="facility-detail-row">
              <p className="detail-title">EST</p>
              <p className="detail">2011</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">License #</p>
              <p className="detail">157202442</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Description</p>
              <p className="detail">
                4 bed, Level 4I Specialized (Severe/Profound/Autism) adult
                residential facility
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Services</p>
              <p className="detail">
                24 hour supervision 2:1 supervision ratio (2 clients to 1
                staff), Private rooms, Window/door chimes, Supportive training,
                Emergency response training, Trained/experienced staff, Modified
                environment, Crises Prevention Intervention, Supervision by a
                Licensed Psychiatric Technician
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Specialization</p>
              <p className="detail">
                Planning and implementing treatment plans to developmentally
                disabled individuals who currently are or have a history of
                mental health problems, mal- adaptive behaviors such as
                inappropriate sexual behaviors, physical or verbal aggression,
                property destruction, and AWOL.
              </p>
            </div>
          </div>
        </div>

        <h2 className="pictures-title">Facility Pictures</h2>
        <div className="masonry">
          <div className="item">
            <StaticImage
              src="../images/facilities/endeavor/endeavor-living1.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={500}
            />
          </div>

          <div className="item">
            <StaticImage
              src="../images/facilities/endeavor/endeavor-living4.jpg"
              alt="living area"
              placeholder="blurred"
              width={500}
              height={600}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/endeavor/endeavor-back2.jpg"
              alt="backyard"
              placeholder="blurred"
              width={400}
              height={450}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/endeavor/endeavor-kitchen1.jpg"
              alt="kitchen"
              placeholder="blurred"
              width={400}
              height={500}
            />
          </div>

          <div className="item">
            <StaticImage
              src="../images/facilities/endeavor/endeavor-back1.jpg"
              alt="backyard"
              placeholder="blurred"
              width={400}
              height={400}
            />
          </div>

          <div className="item">
            <StaticImage
              src="../images/facilities/endeavor/endeavor-living2.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={450}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
